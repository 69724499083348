import React from "react"
require("./jumbotron.css")

const Jumbotron = () => {
  return (
    <div className="jumbotron">
      <div id="jumbotron-writing">
        <h4>Unbiased Reviews For Top Tech Products</h4>
      </div>
      <input placeholder="Search this Website" type="text" />
    </div>
  )
}

export default Jumbotron
