import React from "react"
import "./footer.css"
const Footer = () => {
  return (
    <div className="footer">
      <ul>
        <li>About</li>
        <li>Contact</li>
        <li>Affilate Disclouser</li>
        <li>Terms of Services</li>
        <li>Privacy Policy</li>
      </ul>
      <p>COPYRIGHT © 2020 · Affilate.COM, ALL RIGHTS RESERVED.</p>
      <p>
        Affilate.COM IS A PARTICIPANT IN THE AMAZON SERVICES LLC ASSOCIATES
        PROGRAM, AN AFFILIATE ADVERTISING PROGRAM DESIGNED TO PROVIDE A MEANS
        FOR SITES TO EARN ADVERTISING FEES BY ADVERTISING AND LINKING TO
        AMAZON.COM. AMAZON, THE AMAZON LOGO, AMAZONSUPPLY, AND THE AMAZONSUPPLY
        LOGO ARE TRADEMARKS OF AMAZON.COM, INC. OR ITS AFFILIATES. AS AN AMAZON
        ASSOCIATE WE EARN AFFILIATE COMMISSIONS FROM QUALIFYING PURCHASES.
      </p>
    </div>
  )
}

export default Footer
