import React from "react"
import "./techlogo.css"
import logo from "./tech-logos.png"
const Techlogo = () => {
  return (
    <div className="logo">
      <img alt="d" src={logo} />
    </div>
  )
}

export default Techlogo
