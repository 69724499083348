import React from "react"
import "./header.css"
const Header = () => {
  return (
    <div className="header-main">
      <div className="wrap">
        <img src="https://cdn.10beasts.com/images/logo.png" alt="" />
        <div>
          <a href="/">
            <span>Teach Gear</span>
          </a>
          <a href="/">
            <span>Drones</span>
          </a>
          <a href="/">
            <span>Outdoor Gear</span>
          </a>
          <a href="/">
            <span>Car Accessories</span>
          </a>
          <a href="/">
            <span>Blog</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
