import React from "react"
import PropTypes from "prop-types"
import Header from "./header/header"
import Jumbotron from './jumbotron/jumbotron'
import Techlogo from './techlogo/techlogo'
import Footer from './footer/footer'

import './layout.css'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Jumbotron />
      <Techlogo />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
